<template>
  <el-main>
    <SmallCard :info="smallInfo" @clickFn="$router.push('/extension/group/add')"></SmallCard>
    <!-- <div class="create-group">
      <h1>拼团</h1>
      <p>好友拼团，快速裂变促转化</p>
      <router-link to="/extension/group/add">
        <el-button type="primary" size="small">立即创建</el-button>
      </router-link>
    </div> -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="title" label="活动名称" align="center"></el-table-column>
      <el-table-column prop="date" label="有效时间" align="center"></el-table-column>
      <el-table-column prop="state_desc" label="状态" align="center"></el-table-column>
      <el-table-column prop="group_order_count" label="成团订单数" align="center"></el-table-column>
      <el-table-column prop="group_order_num" label="成团人数" align="center"></el-table-column>
      <el-table-column
        prop="group_order_price"
        label="销售金额(元)	"
        align="center"
      ></el-table-column>
      <el-table-column prop="is_show" label="是否显示" align="center">
        <template v-slot="{ row }">
          <el-switch
            @change="setShow(row)"
            v-model="row.is_show"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="see(row)" type="text" size="small">查看</el-button>
          <el-button @click="del(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate'
import Paging from '@/components/paging'
import SmallCard from '@/components/smallCardBox.vue'
export default {
  components: {
    Paging,
    SmallCard
  },
  data() {
    return {
      smallInfo: {
        img: require('@/assets/smallCardImgs/5.png'),
        title: '拼团',
        desc: '好友拼团，快速裂变促转化',
        btnTxt: '立即创建'
      },
      page: 1,
      rows: 10,
      total_number: 0,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
        this.getList()
      } else {
        this.page = val
        this.getList()
      }
    },
    getList() {
      this.$axios
        .post(this.$api.group.list, {
          page: this.page,
          rows: this.rows
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list
            list.map((item) => {
              item.date = getDateformat(item.start_time) + ' 至 ' + getDateformat(item.end_time)
            })
            this.list = list
            this.total_number = res.result.total_number
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    setShow(row) {
      this.$axios
        .post(this.$api.group.showSet, {
          id: row.id,
          is_show: row.is_show
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('设置成功')
            this.getList()
          } else {
            row.is_show ? (row.is_show = 0) : (row.is_show = 1)
            this.$message.error(res.msg)
          }
        })
    },
    del(row) {
      this.$confirm('请确认是否删除拼团？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.group.del, {
            id: row.id
          })
          .then((res) => {
            if (res.code == 0) {
              this.getList()
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    see(row) {
      this.$router.push({
        path: '/extension/group/detail',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  font-size: 14px;
  background: #fff;
  .create-group {
    width: 280px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #f8f9fa;
    margin-bottom: 15px;
    h1 {
      font-size: 16px;
    }
  }
}
</style>
